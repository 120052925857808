const NoteBase = (obj) => {
  return (
  <div
    className = 'notebase'
    draggable = 'false'
    style= {{ backgroundColor: obj.color }}
  >

  </div>
  );
}

export default NoteBase;
