/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:aa86d81d-e20b-411e-9c33-7a32f22fc0d9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LyDJUQNvH",
    "aws_user_pools_web_client_id": "4g1a13tio5viq66mvm921tnot9",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6rmwggm5kfdwznue2kpifh34sq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2jkq7shhr5dzlmuck7u6i6hp5i",
    "aws_user_files_s3_bucket": "pseudoemployeedbc7cffab8579348228c9f0e5f06513f205628-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
